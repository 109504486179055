import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Nav = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div>
      <div>
        <nav>
        <i className="fas fa-bars fa-2x" id="menu" onClick={toggleMenu}></i>
          <ul id="menulist" style={{ maxHeight: menuOpen ? '350px' : '0px' }}>
          
            <li>
              <Link to="/">
                <div className="button">
                  <div className="icon"><i className="fas fa-home fa-2x"></i></div>
                  <span> Home</span>
                </div>
              </Link>
            </li>
            <li>
              <Link to="about">
                <div className="button">
                  <div className="icon"><i className="far fa-user fa-2x"></i></div>
                  <span> About</span>
                </div>
              </Link>
            </li>
            <li>
              <Link to="Experience">
                <div className="button">
                  <div className="icon"><i className="fas fa-briefcase fa-2x"></i></div>
                  <span> Experience</span>
                </div>
              </Link>
            </li>
            <li>
              <Link to="contact">
                <div className="button">
                  <div className="icon"><i className="far fa-envelope fa-2x"></i></div>
                  <span> Contact</span>
                </div>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Nav;
