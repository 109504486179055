import React from 'react';


const About = () =>{

    return(
        <div>

<div>
    <div className='row about'>
        
        <div className='col-sm-6 col-md-6'>
            <img src='Assets\photo\about.png'/>
        </div>

        <div className='col-sm-6 col-md-6'>
            <h1>Unleashing Imagination and Innovation</h1>
            <p>
            Hello! I am Bijay Lama. Welcome to my portfolio website! I am graduated with Bachelors Degree in Information Technology
            (Bsc Hons CS). My creative journey has been an exciting exploration of imagination
            and innovation. From my early experiences. I have cultivated a passion for pushing boundaries and 
            finding innovative solutions. With a background in Information Technology. I've honed my skills in Web Development and Search 
            Engine Optimization. I thrive on experimenting with new ideas and concepts. I'm excited to continue my creative journey,
            constantly learning and growing, and making a meaningful impact through my work. I am investing all my time in learning new
            skills and a way of developing more of technical and personal development with collaborative skills.
            </p>
        </div>

    </div>
</div>
       



        
</div>
        );
    
}

export default About;