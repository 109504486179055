import React from 'react';

import './App.css';
import Home from './Pages/Home';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Nav from './Pages/Nav';
import Footer from './Pages/Footer';
import Experience from './Pages/Experience';
import {Routes, Route} from 'react-router-dom'

function App() {
  return (
    <>
    <Nav />
<Routes>
    <Route path='/' element={<Home />} />
    <Route path='about' element={<About />} />
    <Route path='contact' element={<Contact />} />
    <Route path='experience' element={<Experience />} />
</Routes>
<Footer />
  </>

  );
}

export default App;
