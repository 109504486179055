import React from 'react';

const Home = () =>{

    return(
        <div>
<div style={{backgroundColor:'#D9D9D9'}}>
    <div className='row'>
    <div className='col-7'>
        <div className='maintext'>
        <p className='text1'> Hello I am</p>
        <p className='text2'> Bijay Lama</p>
        <div className="contactlinks">
            <a href="https://www.facebook.com/profile.php?id=100068322891920" target="_blank"><i class="fab fa-facebook-square fa-2x"></i></a> 
            <a href="https://www.instagram.com/bzay_tmz/" target="_blank"><i class="fab fa-instagram fa-2x"></i></a>
            <a href="https://www.linkedin.com/in/bijay-lama-b77555212/" target="_blank"><i class="fab fa-linkedin fa-2x"></i></a>
        </div>
        </div>
        
    </div>
    <div className='col-5'>
    <img src="assets/photo/Banner.png" alt="Banner Image" className='banner'/>
    </div>
    </div>
</div>




        
</div>
        );
    
}

export default Home;

