import React from "react";

const Footer = () =>{
    return(
        <div>
            <footer>

<p>Bijay Lama © rights reserved</p>
<div className="contactlinks">
        <a href="https://www.facebook.com/profile.php?id=100068322891920" target="_blank"><i class="fab fa-facebook-square fa-2x"></i></a> 
        <a href="https://www.instagram.com/bzay_tmz/" target="_blank"><i class="fab fa-instagram fa-2x"></i></a>
        <a href="https://www.linkedin.com/in/bijay-lama-b77555212/" target="_blank"><i class="fab fa-linkedin fa-2x"></i></a>
</div>

</footer>
</div>
        
    );
}

export default Footer;