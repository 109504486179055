import React, { useRef } from 'react';

const Experience = () =>{
    return(
        <div>
            <h1 style={{textAlign: "center", fontSize: "30px", marginBottom: "50px"}}>Professional Journey</h1>
        <div className='row exp'>
            <div className='col'>
            <div className='experience'>
            <i className="fas fa-file fa-2x"></i>           
            <h2 style={{textAlign: "center", fontSize: "20px"}}><b>Project</b></h2>
            <a href='https://github.com/bzay714/Kalo-Kattu-Website'>
                <h2 style={{textAlign: "center", fontSize: "18px", margin: "20px 0 20px 0"}}>Kalo Kattu</h2>
            </a>
            <p>Kalo Kattu is a local clothing store's fully responsive website,
                 built using the Bootstrap framework for its 
                 frontend. While focusing on visual appeal and user experience,
                 it currently offers only front-end functionalities.</p>
                 </div>
            </div>
            <div className='col'>
            <div className='experience'>
            <i className="fas fa-file fa-2x"></i>           
            <h2 style={{textAlign: "center", fontSize: "20px"}}><b>Project</b></h2>
            <a href='https://github.com/bzay714/Collegewebsite-with-chatbot'>
                <h2 style={{textAlign: "center", fontSize: "18px", margin: "20px 0 20px 0"}}>College Website With Chatbot</h2>
            </a>
            <p>Herald College's fully functional and responsive website, developed as my final year project for a bachelor's 
                degree, features a chatbot. This project utilizes JavaScript, Bootstrap, and Django frameworks to create an 
                engaging user experience.</p>
                </div>
            </div>
            <div className='col'>
            <div className='experience'>
            <i className="fas fa-briefcase fa-2x"></i>
            <h2 style={{textAlign: "center", fontSize: "20px"}}><b>Experience</b></h2>
            <h2 style={{textAlign: "center", fontSize: "18px", margin: "20px 0 20px 0"}}>Web Developer and SEO Expert (15th May, 2022)</h2>
            <p>I am a Web Developer and SEO Expert at Thakur International, where my primary responsibilities include web 
                design and development. Additionally, I specialize in implementing on-page and off-page optimization strategies
                 and also create SEO- friendly content to improve search engine rankings. </p>
                 </div>
            </div>

        </div>
        <div className='box'>

        </div>
        </div>
        );
    
}

export default Experience;