import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

const Contact = () =>{
    const form = useRef();
      
        const sendEmail = (e) => {
          e.preventDefault();
      
          emailjs.sendForm('service_sx76x77', 'template_j2xw7la', form.current, 'r3ytyYs55DrazWoaH')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        };

function Submit (){
        let name = document.forms['contact']["user_name"].value;
        console.log(name);
        }
        
    return(
        <div>

<div>
    <div className='row contact'>
        <div className='col-sm-6 col-md-6'>
            <div className='First'>
            <p className='txt1'>Feel Free To Contact Me</p>
            <p className='txt2'>Let’s Talk</p>
            <p className='txt3'>You can kindly mail me on:<br/>714bzaylama@gmail.com</p>
            </div>
        </div>
        <div className='col-sm-6 col-md-6 '>            
            <div id='form'>
            <div className='form'>
            <h1>Send me a message: </h1>
            <form ref={form} onSubmit={sendEmail} name='Contact'>
                <div>
                    <label htmlFor='Fullname'>Full Name</label>
                    <input type='text' name="user_name" className='fullname' placeholder="Full name" required></input>
                </div>
                <div class="mb-3">
                    <label htmlFor='email'>Email</label>
                    <input type='email' name='user_email' className='email' placeholder="name@example.com" required></input>
                </div>
                <div class="mb-3">
                    <label htmlFor='message'>Message</label>
                    <textarea name='message' rows="3" className='message' placeholder="Message" required></textarea>
                </div>
                <input type="submit" value="Send" className='submit'></input>
            </form>
            {/* <div>
                <span>
                    *Message Sent Successfully
                </span>
            </div>

            <div>
                <span>
                    *Error
                </span>
            </div> */}
            </div>
        </div>
        </div>

    </div>
</div>


        
</div>
        );
    
}

export default Contact;